import request from '@/util/request'
export function rechargeListV2(imei,typeList) {
    return request({
        url: '/pay/v2/recharge/list',
        method: 'post',
        data:{imei,typeList}
    })
}
export function createOrder(imei,rechargeId,amount) {
    return request({
        url: '/pay/saas/order',
        method: 'post',
        data:{imei,rechargeId,amount}
    })
}
export function checkOrder(orderNo){
    return request({
        url: '/pay/v2/recharge/pay/check',
        method: 'post',
        data:{orderNo}
    })
}
