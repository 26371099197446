<template>
<div style="padding: 10px">
  <van-divider content-position="left">{{$t('设备信息')}}</van-divider>
  <div class="device-info">
    <div>
      {{$t('设备名称')}}：{{ recharge.deviceName || $t("未命名设备") }}
    </div>
    <div>
      IMEI：{{ recharge.imei }}
    </div>
    <div>
      {{ recharge.info }}
    </div>
  </div>
  <van-divider content-position="left">{{$t('支付项目')}}</van-divider>
  <van-radio-group v-model="radio">
      <van-cell :title="item.name" clickable @click="radio = item.id" :value="item.priceName" v-for="item in recharge.list" :key="item.id">
        <!-- 使用 right-icon 插槽来自定义右侧图标 -->
        <template #icon>
          <van-radio :name="item.id" />
        </template>
      </van-cell>
  </van-radio-group>
  <div style="padding-top: 30px">
    <van-button @click="pay(1)" block type="primary">{{$t('微信支付')}}</van-button>
  </div>
  <div style="padding-top: 30px">
    <van-button @click="pay(2)" block type="info">{{$t('支付宝支付')}}</van-button>
  </div>
</div>
</template>

<script>
import {readCommonParam,getImei} from "../../util/session-storage-utils";
import {CellGroup, Cell, Radio, RadioGroup, Button, Divider, Notify, Dialog} from "vant"
import {checkOrder, createOrder, rechargeListV2} from "../../api/pay";
import {orderPay} from "../../util/jsinterfaces-util";
export default {
  name: "Pay",
  components:{
    [CellGroup.name]:CellGroup,
    [Cell.name]:Cell,
    [Radio.name]:Radio,
    [RadioGroup.name]:RadioGroup,
    [Button.name]:Button,
    [Divider.name]:Divider,
  },
  created() {
    document.title = this.$t("支付");
    readCommonParam(this)
    this.loadData();
  },
  data(){
    return{
      type:this.$route.query.type,
      radio:null,
      recharge: {}
    }
  },
  methods:{
    loadData(){
      rechargeListV2(getImei(),[this.$route.query.type]).then(resp => {
        let rechargeList = resp.data
        if(rechargeList && rechargeList.length > 0){
          this.recharge = rechargeList[0];
        }
      })
    },
    checkOrder(orderNo){
      checkOrder(orderNo).then(res => {
        let result = res.data;
        if(result){
          Notify({ type: 'success', message: this.$t('支付成功') });
          this.loadData();
        }else{
          Notify({ type: 'danger', message: this.$t('支付失败') });
        }
      })
    },
    pay(type){
      if(this.radio == null){
        Notify(this.$t('请选择支付项'));
        return
      }
      createOrder(getImei(),this.radio).then(res => {
        let order = res.data;

        orderPay(order.orderNo,type);
        setTimeout(()=>{
          Dialog.confirm({
            title: this.$t('支付确认'),
            message: this.$t('订单支付成功'),
          })
              .then(() => {
                // on confirm
                this.checkOrder(order.orderNo);
              })
              .catch(() => {
                // on cancel
              });
        },500)

      })

    }
  }

}
</script>

<style scoped>
.device-info{
  font-size: 14px;
  padding: 10px;
  margin: 10px 0;
  background-color: #FFFFFF;

}
.device-info div {
  padding: 5px 0;
}
</style>
